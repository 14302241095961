.vertical-skills-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 200vh;
  position: relative;
  gap: 18px;

  .heading {
    font-size: 56px;
    color: var(--black);
    font-weight: 600;
    text-align: center;
    width: 100%;
  }

  .bubble-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    position: relative;

    .bubble {
      width: 80px;
      height: 80px;
    }

    .name-and-progress {
      display: flex;
      flex-direction: column;
      gap: 0px;
      width: 100%;

      .bubble-text {
        font-size: 18px;
        color: var(--black);
      }

      .progress-bar-flex {
        display: flex;
        width: 100%;
        gap: 18px;

        .progress-bar {
          width: 100%;
          height: 10px;
          background-color: $grey;
          position: relative;
          border-radius: 5px;
          margin-top: 10px;

          .progress {
            width: 50%;
            height: 100%;
            background-color: $black;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
