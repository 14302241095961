.footer-wrapper {
  width: 100%;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--black);
  color: var(--white);
  padding: 12px 24px;
  border-radius: 100px;
  margin: 0px auto;

  .left-heading {
    font-size: 18px;
    font-weight: 600;
  }

  .right-icons {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    align-items: center;

    div {
      height: 24px;
    }

    .icon {
      margin: 0px;
      font-size: 24px;
      color: var(--white);
      cursor: pointer;
    }
  }
}

[data-theme="dark"] {
  .footer {
    background-color: var(--white);
    border: 2px solid var(--black);

    .left-heading,
    .icon {
      color: var(--black);
    }
  }
}
