.numbers-and-stats {
  // position: sticky;
  // position: -webkit-sticky;
  // top: 30vh;
  // height: 200vh;
  // max-height: 1550px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 48px;
  margin: 64px 0px;

  .center-text {
    text-align: center;
    .text-p {
      font-size: 24px;
      color: var(--black);
      text-align: center;
      font-weight: 600;
    }
  }

  .card-container {
    width: 130px;
    height: 130px;
    position: relative;
    z-index: 1000;
    .card {
      width: 130px;
      height: 130px;
      position: absolute;
      top: 0;

      .card-content {
        width: 100%;
        height: 100%;
        background-color: #333;
        border-radius: 30px;

        .icon-img {
          width: 100%;
          height: 100%;
        }
      }

      &.front-card {
        z-index: 10;
      }

      &.back-card {
        z-index: 0;
      }
    }
  }

  .card-text {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    color: var(--black);

    .orange {
      color: $primary-orange;
    }
  }
}

@media screen and (max-width: 600px) {
  .numbers-and-stats {
    gap: 24px;
    margin: 24px 0px;
    height: auto;
    position: unset;
    top: unset;

    .center-text {
      .text-p {
        font-size: 20px;
      }
    }

    .card-container {
      width: 75px;
      height: 75px;

      .card {
        width: 75px;
        height: 75px;

        .card-content {
          width: 100%;
          height: 100%;
          background-color: #333;
          border-radius: 12px;
        }
      }
    }

    .card-text {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      color: var(--black);

      .orange {
        color: $primary-orange;
      }
    }
  }
}
