.navbar {
  background-color: rgba($grey, 0.8);
  padding: 12px 36px;
  border-radius: 36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  overflow: hidden;
  width: auto;
  min-width: 175px;
  position: fixed;
  top: 16px;
  z-index: 9998;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  .heading {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
  }

  .menu-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    width: 20px;
    height: 20px;

    .line {
      width: 20px;
      height: 2.5px;
      background-color: var(--black);
      position: absolute;
      transform-origin: center;
    }
  }

  .links {
    margin-left: auto;
    display: flex;
    gap: 12px;

    p {
      text-decoration: none;
      color: var(--black);
      font-size: 16px;
      font-weight: 500;
      transition: all 0.3s;
      padding: 2px;
      cursor: pointer;

      &.hidden {
        display: none;
      }
      &:hover {
        background-color: var(--white);
        padding: 2px 20px;
        border-radius: 24px;
      }
    }
  }
}

[data-theme="dark"] {
  .navbar {
    background-color: rgba($black, 0.8);
    border: 2px solid $white;

    .links {
      p {
        color: $text-grey;
        &:hover {
          background-color: #1e1e1e;
          color: $white;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .navbar {
    padding: 12px 24px;
    gap: 12px;

    .heading {
      font-size: 16px;
    }

    .links {
      margin-right: auto;
      display: flex;
      gap: 12px;

      p {
        font-size: 14px;
      }
    }
  }

  [data-theme="dark"] {
    .navbar {
      background-color: rgba($black, 0.8);
      border: 2px solid $white;

      .links {
        p {
          color: $white;
          &:hover {
            background-color: #1e1e1e;
            color: $white;
          }
        }
      }
    }
  }
}
