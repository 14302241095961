.find-my-work {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;

  .heading {
    font-size: 36px;
    color: var(--black);
    text-align: center;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .work-content {
    padding: 36px 0px;
    display: flex;
    // overflow-x: auto;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;
    max-width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .find-my-work {
    .heading {
      font-size: 20px;
      margin-bottom: 16px;
    }

    .work-content {
      padding: 24px 0px;
      gap: 18px;
    }
  }
}
