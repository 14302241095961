.toggle-button {
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
  padding: 10px;
  border: none;
  background-color: rgba($grey, 0.8);
  color: $black;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  transition: background-color 0.3s, color 0.3s;
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 9998;
}

[data-theme="dark"] {
  .toggle-button {
    background-color: rgba($black, 0.8);
    border: 2px solid $white;
    color: $white;
  }
}

@media screen and (max-width: 600px) {
  .toggle-button {
    top: unset;
    bottom: calc(0dvh + 24px);
    right: 16px;
  }
}
