.btn {
  padding: 8px 24px;
  border-radius: 36px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  transition: all 0.3s ease;
  border: 2px solid transparent;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.primary {
    color: var(--white);
    border: 2px solid var(--black);
    background-color: var(--white);
    display: flex;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--black);
      z-index: -2;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: var(--white);
      transition: all 0.3s;
      z-index: -1;
    }

    &:hover {
      color: var(--black);
      border-color: var(--border-grey);

      &:before {
        width: 100%;
      }
    }
  }

  &.outline {
    color: var(--black);
    border: 2px solid var(--border-grey);
    display: flex;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--white);

      z-index: -2;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: var(--black);
      transition: all 0.3s;
      z-index: -1;
    }

    &:hover {
      color: var(--white);
      border: 2px solid var(--black);

      &:before {
        width: 100%;
      }
    }
  }

  &:active {
    transform: scale(0.95);
  }
}
