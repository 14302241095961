.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--grey);
  padding: 8px;
  border-radius: 48px;
  width: fit-content;

  .tab {
    position: relative;
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    color: var(--text-grey);
    font-weight: 600;
    &.active {
      color: var(--black);
      z-index: 1;
    }

    .active-tab-indicator {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--white);
      border-radius: 25px;
      z-index: -1;
    }
  }
}

[data-theme="dark"] {
  .tabs {
    background-color: var(--white);
    border: 2px solid var(--black);
    .tab {
      .active-tab-indicator {
        background-color: #1e1e1e;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .tabs {
    padding: 6px;

    .tab {
      position: relative;
      margin: 0 8px;
      padding: 8px 16px;
      font-size: 14px;
    }
  }

  [data-theme="dark"] {
    .tabs {
      background-color: var(--white);
      border: 2px solid var(--black);
      .tab {
        .active-tab-indicator {
          background-color: #1e1e1e;
        }
      }
    }
  }
}
