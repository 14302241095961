.work-experience-main-wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 220vh;
  position: relative;

  .fixed-heading {
    position: absolute;
    top: 100px;
    z-index: 1;
    text-align: center;
    width: 100%;
    font-size: 48px;
    .orange {
      color: $primary-orange;
    }
  }

  .left-column {
    position: sticky;
    top: 0;
    width: 50%;
    height: 100vh;
  }

  .right-column {
    width: 50%;
    padding: 50px;
    margin: auto;
  }

  .work-experience-section {
    opacity: 0;
    transform: translateY(100px);
    margin-bottom: 150px;
    text-align: center !important;

    .job-title {
      font-size: 28px;
      font-weight: 700;

      .orange {
        color: $primary-orange;
      }
    }

    .duration {
      font-size: 18px;
      font-weight: 700;
    }

    .work-ex {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 400;
      color: $text-grey;

      .black {
        color: var(--black);
        font-weight: 500;
      }
    }
  }

  .parts-assembling {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    cursor: grab;
    max-height: 1000px;
  }
}

@media screen and (max-width: 600px) {
  .work-experience-main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    margin-bottom: 0px;
    position: unset;

    .fixed-heading {
      position: unset;
      z-index: 1;
      text-align: center;
      width: 100%;
      font-size: 24px;
      margin-bottom: 24px;
      .orange {
        color: $primary-orange;
      }
    }

    .left-column {
      display: none;
    }

    .right-column {
      width: 100%;
      padding: 0px;
      margin: 0px;

      .work-experience-section {
        opacity: 1;
        transform: translateY(0px);
        margin-bottom: 48px;
        text-align: center !important;

        .job-title {
          font-size: 24px;
          font-weight: 700;

          .orange {
            color: $primary-orange;
          }
        }

        .duration {
          font-size: 16px;
          font-weight: 700;
        }

        .work-ex {
          margin-top: 8px;
          font-size: 14px;
          font-weight: 400;
          color: $text-grey;

          .black {
            color: var(--black);
            font-weight: 500;
          }
        }
      }
    }

    .parts-assembling {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 500px;
      margin-top: 80px;
      cursor: pointer;
      display: none;
    }
  }
}
