.custom-mouse {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--black);
  transition: width 0.3s, height 0.3s, opacity 0.3s, background-color 0.3s;
  pointer-events: none;
  z-index: 9999;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 12px;
    color: $white;
  }
}

@media screen and (max-width: 600px) {
  .custom-mouse {
    display: none;
  }
}
