.work-carousel {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .carousel-container {
    padding: 36px 16px;
    background-color: var(--black);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    position: relative;
    border-radius: 36px;
    overflow-x: auto;
    position: relative;

    .heading {
      font-size: 24px;
      color: var(--white);

      a {
        color: var(--white);
        text-decoration: none;
      }
    }

    .desc {
      font-size: 16px;
      color: var(--white);
      text-align: center;
    }

    .images-container {
      width: 100%;
      overflow: hidden;

      .swiper {
        width: 577px;
        height: 400px;
      }

      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;

        .carousel-image {
          width: 577px;
          height: 337px;
          object-fit: cover;
          border-radius: 16px;
        }
      }
    }
  }
}

[data-theme="dark"] {
  .work-carousel {
    .carousel-container {
      background-color: #1e1e1e;
      .heading,
      .desc,
      a {
        color: var(--black);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .work-carousel {
    .carousel-container {
      padding: 24px 16px;
      gap: 12px;
      border-radius: 18px;

      .heading {
        font-size: 20px;
        text-align: center;
      }

      .desc {
        font-size: 14px;
      }

      .images-container {
        width: 100%;
        overflow: hidden;

        .swiper {
          width: 300px;
          height: 250px;
        }

        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;

          .carousel-image {
            width: 300px;
            height: 200px;
            object-fit: cover;
            border-radius: 8px;
          }
        }
      }
    }
  }

  [data-theme="dark"] {
    .work-carousel {
      .carousel-container {
        background-color: #1e1e1e;
        .heading,
        .desc,
        a {
          color: var(--black);
        }
      }
    }
  }
}
